import React, { useMemo, useRef } from 'react'

import Layout from '../../components/layout/Layout'
import Header from '../../components/layout/Header'
import BotButton from '../../components/landBot/BotButton'
import ThreeFeatures from '../../components/carousels/ThreeFeatures'
import ParallaxContainer from '../../components/parallax/ParallaxContainer'
import Card from '../../components/general/Card';
import FloatingContainer from '../../components/floats/FloatingContainer'
import FloatingShape from '../../components/floats/FloatingShape'
import Slider from '../../components/general/Slider'
import GoogleRating from '../../components/general/GoogleRating'
import ScrollIndicator from '../../components/general/ScrollIndicator'

import OfficeDesignHero from '../../images/offices/Office-Design-Hero.jpg'
import TechOffice from '../../images/offices/Tech.jpeg'
import CreativeOffice from '../../images/offices/Creative.jpeg'
import ScandiOffice from '../../images/offices/Scandi.jpg'
import ProfessionalOffice from '../../images/offices/Professional.jpeg'
import WarehouseOffice from '../../images/offices/Warehouse.jpg'
import CoWorkingOffice from '../../images/offices/Co-working.jpg'
import MonzoLogo from '../../images/clients/monzo.png'
import AtomicoLogo from '../../images/clients/atomico.png'
import MadeLogo from '../../images/clients/made.png'
import MMCVenturesLogo from '../../images/clients/mmc-ventures.png'
import TalaLogo from '../../images/clients/tala.jpg'
import WeWorkLogo from '../../images/clients/wework.png'
import DesignAndBuildIcon from '../../images/other/design-and-build.svg'
import ThirdwayLogo from '../../images/clients/thirdway.png'
import WhitepaperLogo from '../../images/clients/whitepaper.png'
import PeldonRoseLogo from '../../images/clients/peldon-rose.png'
import OktraLogo from '../../images/clients/oktra.svg'
import MarisLogo from '../../images/clients/maris.svg'
import ArchitectsAndInteriorDesignersIcon from '../../images/other/architects-&-interior-designers.svg'
import BluebottleLogo from '../../images/clients/bluebottle.png'
import JackDawLogo from '../../images/clients/jack-daw.png'
import BGYLogo from '../../images/clients/bgy.png'
import SODALogo from '../../images/clients/soda.png'
import ProjectManagersIcon from '../../images/other/project-managers.svg'
import StoreyLogo from '../../images/clients/storey.png'
import ERALogo from '../../images/clients/era.svg'
import FurnitureSuppliersIcon from '../../images/other/furniture-suppliers.svg'
import EportaLogo from '../../images/clients/eporta.png'
import TribesLogo from '../../images/clients/tribes.svg'
import OWNLogo from '../../images/clients/ow-n.png'
import PhoneBoothSolutionsIcon from '../../images/other/phone-booth-solutions.svg'
import ROOMLogo from '../../images/clients/room.svg'
import FrameryLogo from '../../images/clients/framery.png'
import HiBobLogo from '../../images/clients/hi-bob.png'
import OfficeDesign1 from '../../images/offices/Office-Design-1.jpeg'
import OfficeDesign2 from '../../images/offices/Office-Design-2.jpg'
import OfficeDesign3 from '../../images/offices/Office-Design-3.jpeg'

const OfficeDesign = ({ location }) => {
    const headerCTARef = useRef();
    const headerCTAPosition = useMemo(() => headerCTARef?.current?.getBoundingClientRect(), []);
    
    return (
        <Layout
            location={location}
            activePage="/services"
            gutters={false}
            container={false}
            mainButton={(
                <BotButton
                    type="secondary"
                    className="py-2 relative text-2xl md:text-xl shadow-charlie whitespace-no-wrap px-3 mt-8 md:mt-2"
                    botName="OfficeDesignLandBot"
                >
                    Start your office design
                </BotButton>
            )}
        >
            <Header title="Home - Find your perfect office space" />

            <div
                className="grid grid-cols-3 xl:ml-16 relative mb-0"
                style={{ gridTemplateRows: 'auto' }}
            >
                <div className="col-start-1 col-end-4 row-start-1 row-end-2 text-left mt-24 mb-8 sm:mt-4 flex items-start flex-col justify-center mx-0 md:w-9/12 sm:col-end-3 md:mt-40">
                    <div className="max-w-full mx-auto md:ml-0">
                        <h1 className=" transition-quick text-3xl md:text-4xl text-center sm:text-left mx-8 md:mx-0 sm:text-left">
                            Need inspiration and help in
                            {' '}
                            <span className="border-b-2 border-secondary font-bold">designing and constructing</span>
                            {' '}
                            your perfect workspace?
                        </h1>
                    </div>
                </div>
                <div
                    className="hidden sm:block justify-self-end col-start-2 col-end-4 row-start-1 row-end-2 lg:row-end-3 relative"
                    style={{
                        width: '65%',
                        maxWidth: '700px',
                    }}
                >
                    <div className="relative">
                        <img className="mt-48" src={OfficeDesignHero} alt="Office Design Hero" />
                    </div>
                </div>
                <div className="col-start-1 col-end-4 row-start-2 row-end-3  relative">
                    <ParallaxContainer
                        ref={headerCTARef}
                        className="flex-col pt-16 sm:pt-4 no-wrap"
                        weighting={2}
                        reverse
                    >
                        <Card
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            className="max-w-xs md:max-w-md mx-auto sm:max-w-sm md:mx-0 md:ml-10 w-full"
                            innerSpacing={0}
                        >
                            <p className="p-8">
                                We
                                {' '}
                                <span className="border-b-2 border-secondary inline font-bold">
                                work with the best architects, interior designers and construction teams
                            </span>
                                {' '}
                                to deliver your new office HQ on time, on budget, and on point.
                            </p>
                            <div
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-offset={-100}
                                className="absolute flex items-start bottom-0 mx-auto sm:mx-0 px-8 sm:px-0"
                            >
                                <div className="relative md:left-32 left-0 sm:-left-4 top-16 -mb-4">
                                    <BotButton
                                        botName="OfficeDesignLandBot"
                                        type="secondary"
                                        className="py-1 uppercase text-center font-bold shadow-charlie sm:text-2xl w-68 sm:w-96"
                                    >
                                        Start your office design
                                    </BotButton>
                                    <p className="text-center text-xs">Answer some quick questions, we’ll do the rest.</p>
                                </div>
                                <GoogleRating className="relative left-60 top-16 -mb-4 sm:ml-40 xl:ml-64 mt-2" />
                            </div>
                        </Card>
                    </ParallaxContainer>
                </div>
                <ScrollIndicator ctaPostition={headerCTAPosition} />
            </div>

            <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
                className="max-w-6xl mx-auto px-6 relative my-24"
                style={{ marginTop: '20rem' }}
            >
                {/* <FloatingContainer
                    className="lg:w-1/2 w-full mx-auto lg:left-1/2 -top-32 lg:top-0 "
                    floatSpeed="custom"
                    speed={5000}
                    floatType="wobbleDrift"
                    height="400px"
                >
                    <FloatingShape height={180} width={180} shape="polygon" />
                </FloatingContainer> */}
                <ThreeFeatures
                    features={[
                        {
                            title: 'By your side',
                            subTitle: 'You don’t need to go it alone through all the unknowns. We’ve worked with hundreds of scaleups and unicorns helping them through the myriad of options when designing and constructing their perfect office space.',
                        },
                        {
                            title: 'Pick and choose',
                            subTitle: 'Use our platform to pick and choose the best designers, architects, project managers and construction teams for your brief. We’ll be on hand to offer our honest and independent advice along the way.',
                        },
                        {
                            title: 'One stop shop',
                            subTitle: 'Want a one-stop shop to deliver everything for you? Both Design and build without the hassle? It can be done!',
                        }
                    ]}
                />
            </div>

            <div className="mx-auto text-center my-24 relative" style={{ maxHeight: '500px' }}>
                {/* <FloatingContainer
                    className="lg:w-1/2 w-full mx-auto lg:left-1/4 -top-32 lg:top-0 "
                    floatSpeed="custom"
                    speed={5000}
                    floatType="spin"
                    height="200px"
                >
                    <FloatingShape height={160} width={160} shape="halfMoon" />
                </FloatingContainer> */}
                <ParallaxContainer weighting={1.3} reverse early={100} className="justify-center">
                    <Card
                        style={{
                            height: '300px',
                            maxWidth: '400px',
                        }}
                        type="secondary"
                        className="flex flex-col flex-shrink-0 w-95p md:w-full md:-mr-12 md:ml-0 top-32 md:top-48 absolute md:relative overflow-hidden transition-quick -bottom-48 shadow-xl z-10"
                    >
                        <Slider>
                            <div className="flex flex-col items-center justify-between h-full">
                                <p className="text-xl text-center leading-6 my-auto pb-2 md:pb-8">
                                    “We love you Kontor, we do!!
                                    {' '}
                                    <span className="font-bold">So excited about this office space</span>
                                    {' '}
                                    and a beautiful HiBob x Whitepaper fit-out”
                                </p>
                                <img className="h-16" src={HiBobLogo} alt="HiBob Logo" />
                            </div>
                        </Slider>
                    </Card>
                    <img
                        className="inline-block md:mt-40 transition-quick self-end max-w-90p md:max-w-sm"
                        src={OfficeDesign1}
                        alt="Office Design"
                    />
                </ParallaxContainer>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="max-w-6xl mx-auto px-6 mb-24 mt-64 pt-40">
                {/* <FloatingContainer
                    className="w-1/2 left-1/4"
                    floatSpeed="custom"
                    speed={10000}
                    floatType="largeWobbleDrift"
                    height="300px"
                >
                    <FloatingShape height={110} width={110} shape="circle" />
                </FloatingContainer> */}
                <h2 className="text-center">
                    Stuck for ideas? <span className="border-b-2 border-secondary font-bold">Here’s some styles that might inspire your new office</span>
                </h2>
                <p className="text-center my-8 md:mb-24">
                    No two offices are the same but a lot of the requirements are similar; quiet spaces for developers,
                    open plan for the sales team, plenty of meeting rooms and space to collaborate.
                    How it looks though, that’s the fun part and up to you!
                </p>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="mb-24 px-8">
                <div className="flex justify-center mx-auto flex-wrap -my-3" style={{ maxWidth: '1400px' }}>
                    <Card
                        className="w-full sm:w-80 md:w-72 lg:w-80 sm:max-w-md m-4 bg-white flex flex-col justify-between"
                        innerSpacing={6}
                    >
                        <div>
                            <img className="w-full h-auto mb-4 object-cover h-48" src={TechOffice} alt="Tech Office" />
                            <h4 className="uppercase mb-2">
                                <span className="border-b-2 border-secondary pb-1">Tech</span>
                            </h4>
                            <p className="text-base">
                                Cool, functional and flexible. From team scrums to all-hands.
                                Allowing scaleups to do just that without having to knock down walls every 5 minutes.
                            </p>
                        </div>
                        <div>
                            <p className="text-base font-bold mb-0">As loved by:</p>
                            <img className="h-16 -mt-4 -mb-4" src={MonzoLogo} alt="Monzo logo" />
                        </div>
                    </Card>
                    <Card
                        className="w-full sm:w-80 md:w-72 lg:w-80 sm:max-w-md m-4 md:mt-12 md:-mb-4 bg-secondary flex flex-col justify-between"
                        innerSpacing={6}
                    >
                        <div>
                            <img className="w-full h-auto mb-4 object-cover h-48" src={CreativeOffice} alt="Creative Office" />
                            <h4 className="uppercase mb-2">
                                <span className="border-b-2 border-primary pb-1">Creative</span>
                            </h4>
                            <p className="text-base">
                                Spaces to inspire. To let ideas and people flourish.
                                Easy to say, hard to deliver. But when you see it, you know.
                            </p>
                        </div>
                        <div>
                            <p className="text-base font-bold mb-0">As loved by:</p>
                            <img className="w-auto h-6" src={AtomicoLogo} alt="Atomico logo" />
                        </div>
                    </Card>
                    <Card
                        className="w-full sm:w-80 md:w-72 lg:w-80 sm:max-w-md m-4 bg-white md:bg-secondary lg:bg-white flex flex-col justify-between"
                        innerSpacing={6}
                    >
                        <div>
                            <img className="w-full h-auto mb-4 object-cover h-48" src={ScandiOffice} alt="High rise Office" />
                            <h4 className="uppercase mb-2">
                            <span className="border-b-2 border-secondary md:border-primary lg:border-secondary pb-1">
                                Scandi
                            </span>
                            </h4>
                            <p className="text-base">
                                Taking cues from our Scandinavian friends to deliver something functional
                                yet minimal and refined whilst oozing style.
                            </p>
                        </div>
                        <div>
                            <p className="text-base font-bold mb-0">As loved by:</p>
                            <img className="w-auto h-6" src={MadeLogo} alt="Made.com Logo" />
                        </div>
                    </Card>
                    <Card
                        className="w-full sm:w-80 md:w-72 lg:w-80 md:mt-12 md:-mb-4 md:mx-4 sm:max-w-md m-4 lg:m-4 bg-secondary md:bg-white lg:bg-secondary flex flex-col justify-between"
                        innerSpacing={6}
                    >
                        <div>
                            <img className="w-full h-auto mb-4 object-cover h-48" src={ProfessionalOffice} alt="High rise Office" />
                            <h4 className="uppercase mb-2">
                            <span className="border-b-2 border-primary md:border-secondary lg:border-primary pb-1">
                                Professional
                            </span>
                            </h4>
                            <p className="text-base">
                                Having a professional looking space doesn't have to mean it’s boring. Have the best of both
                                worlds by mixing creative elements with a more professional aesthetic to appeal to all parties.
                            </p>
                        </div>
                        <div>
                            <p className="text-base font-bold mb-0">As loved by:</p>
                            <img className="w-auto h-8" src={MMCVenturesLogo} alt="MMC Ventures logo" />
                        </div>
                    </Card>
                    <Card
                        className="w-full sm:w-80 md:w-72 lg:w-80 sm:max-w-md md:mt-4 md:mb-20 m-4 lg:mx-4 lg:mt-12 lg:-mb-4 bg-white flex flex-col justify-between"
                        innerSpacing={6}
                    >
                        <div>
                            <img className="w-full h-auto mb-4 object-cover h-48" src={WarehouseOffice} alt="High rise Office" />
                            <h4 className="uppercase mb-2">
                                <span className="border-b-2 border-secondary pb-1">Warehouse</span>
                            </h4>
                            <p className="text-base">
                                Exposed brick, timber beams, original floorboards. Startup cliché? Not if it’s done well.
                                Combining modern elements with the classic pieces to deliver those warehouse vibes. Nice!
                            </p>
                        </div>
                        <div>
                            <p className="text-base font-bold mb-0">As loved by:</p>
                            <img className="w-auto h-8" src={TalaLogo} alt="Tala logo" />
                        </div>
                    </Card>
                    <Card
                        className="w-full sm:w-80 md:w-72 lg:w-80 sm:max-w-md md:my-12 lg:m-4 m-4 bg-secondary flex flex-col justify-between"
                        innerSpacing={6}
                    >
                        <div>
                            <img className="w-full mb-4 object-cover h-48" src={CoWorkingOffice} alt="High rise Office" />
                            <h4 className="uppercase mb-2">
                                <span className="border-b-2 border-primary pb-1">Co-working</span>
                            </h4>
                            <p className="text-base">
                                Fun, energetic and creating a community buzz.
                                Jumping from a hot desk to phone booths to the bar?! All under one roof.
                            </p>
                        </div>
                        <div>
                            <p className="text-base font-bold mb-0">As loved by:</p>
                            <img className="w-auto h-6" src={WeWorkLogo} alt="WeWork logo" />
                        </div>
                    </Card>
                </div>

                <div className="relative mt-32 text-center">
                    <BotButton
                        botName="OfficeDesignLandBot"
                        type="secondary"
                        className="px-2 py-3 uppercase text-center font-bold shadow-charlie sm:text-2xl w-72 sm:w-96"
                    >
                        Start your office design
                    </BotButton>
                    <p className="text-xs">Answer some quick questions, we’ll do the rest.</p>
                </div>
            </div>

            <div
                style={{ maxHeight: '500px' }}
                data-aos="fade-up"
                data-aos-duration="1000"
                className="mx-auto mt-48 text-center relative"
            >
                {/* <FloatingContainer
                    className="lg:w-1/2 w-full mx-auto lg:left-3/4 -top-32 lg:top-0"
                    floatSpeed="custom"
                    speed={5000}
                    floatType="spin"
                    height="200px"
                >
                    <FloatingShape height={160} width={160} shape="halfMoon" />
                </FloatingContainer> */}
                <ParallaxContainer weighting={2} className="justify-center" reverse>
                    <img
                        className="self-start ml-40 md:ml-0 h-full transition-quick max-w-sm"
                        src={OfficeDesign2}
                        alt="Office Design"
                    />
                    <img
                        className="-ml-8 mt-48 transition-quick h-full max-w-xl z-10"
                        src={OfficeDesign3}
                        alt="Office Design"
                    />
                    <Card
                        style={{
                            height: '300px',
                            maxWidth: '400px',
                        }}
                        type="secondary"
                        className="flex flex-col flex-shrink-0 w-95p md:w-full md:-ml-64 top-0 md:top-8 transition-quick absolute md:relative overflow-hidden -bottom-48 shadow-xl z-20"
                    >
                        <Slider>
                            <div className="flex flex-col items-center justify-between h-full">
                                <p className="text-xl text-center leading-6 my-auto pb-2 md:pb-8">
                                    “The AIS design and build team
                                    {' '}
                                    <span className="font-bold">really listened to our needs</span>
                                    {' '}
                                    and were really receptive
                                    to feedback, which makes for a truly collaborative and engaging experience.”
                                </p>
                                <img className="h-16" src={MonzoLogo} alt="Monzo Logo" />
                            </div>
                        </Slider>
                    </Card>
                </ParallaxContainer>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="max-w-6xl mx-auto px-6 mb-40 mt-48">
                {/* <FloatingContainer
                    className="w-1/4"
                    floatSpeed="custom"
                    speed={10000}
                    floatType="largeWobbleDrift"
                    height="300px"
                >
                    <FloatingShape height={110} width={110} shape="circle" />
                </FloatingContainer> */}
                <h2 className="text-center">
                    Where to start? <span className="border-b-2 border-secondary font-bold">We work with the best in the business to make it happen</span>
                </h2>
                <p className="text-center my-8 md:mb-24">
                    Use our platform to pick and choose the best designers, architects, project managers,
                    construction teams and more for your brief.
                    We’ll be on hand to offer our honest and independent advice along the way.
                </p>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="max-w-7xl px-8 mx-auto">
                <div className="-m-16">
                    <div className="flex items-end flex-wrap justify-between m-16">
                        <div className="flex items-start w-full w- md:w-4/12">
                            <img src={DesignAndBuildIcon} alt="Design & Build" className="mr-4 h-10 w-10" />
                            <div>
                                <h6 className="text-xl font-bold">Design & Build</h6>
                                <p className="text-base leading-tight mt-2 mb-0">
                                    Think of it like a one-stop-shop where they take care of everything in-house
                                    from the initial designs to the construction and the all important finishing touches.
                                    Meaning you deal with a single point of contact from start to finish.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-7/12">
                            <p className="text-lg mt-8 font-bold">Example Partners:</p>
                            <div className="flex items-center flex-wrap -m-4">
                                <img className="w-auto h-16 m-4" src={ThirdwayLogo} alt="Thirdway logo" />
                                <img className="w-auto h-5 m-4" src={WhitepaperLogo} alt="Whitepaper logo" />
                                <img className="w-auto h-4 m-4" src={PeldonRoseLogo} alt="Peldon Rose logo" />
                                <img className="w-auto h-10 m-4" src={OktraLogo} alt="Oktra logo" />
                                <img className="w-auto h-4 m-4" src={MarisLogo} alt="Maris logo" />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-end flex-wrap justify-between m-16">
                        <div className="flex items-start w-full w- md:w-4/12">
                            <img
                                src={ArchitectsAndInteriorDesignersIcon}
                                alt="Architects & Interior Designers"
                                className="h-10 w-10 mr-4"
                            />
                            <div>
                                <h6 className="text-xl font-bold">Architects & Interior Designers</h6>
                                <p className="text-base leading-tight mt-2 mb-0">
                                    Let them work their magic and design something special for you and your team.
                                    It’s the more traditional approach, then appointing a project manager
                                    and construction team independently to deliver the finished product.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-7/12">
                            <p className="text-lg mt-8 font-bold">Example Partners:</p>
                            <div className="flex items-center -m-4">
                                <img className="w-auto h-8 m-4" src={BluebottleLogo} alt="Bluebottle logo" />
                                <img className="w-auto h-4 m-4" src={JackDawLogo} alt="Jack Daw logo" />
                                <img className="w-auto h-12 m-4" src={BGYLogo} alt="BGY logo" />
                                <img className="w-auto h-8 m-4" src={SODALogo} alt="SODA logo" />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-end flex-wrap justify-between m-16">
                        <div className="flex items-start w-full w- md:w-4/12">
                            <img src={ProjectManagersIcon} alt="Project Managers" className="h-10 w-10 mr-4" />
                            <div>
                                <h6 className="text-xl font-bold">Project Managers</h6>
                                <p className="text-base leading-tight mt-2 mb-0">
                                    Responsible for the day to day running of your office construction project,
                                    protecting your interests, budgets and design from start to finish. Nice!
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-7/12">
                            <p className="text-lg mt-8 font-bold">Example Partners:</p>
                            <div className="flex items-center -m-4">
                                <img className="w-auto h-12 m-4" src={StoreyLogo} alt="Storey logo" />
                                <img className="w-auto h-5 m-4" src={ERALogo} alt="ERA logo" />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-end flex-wrap justify-between m-16">
                        <div className="flex items-start w-full w- md:w-4/12">
                            <img src={FurnitureSuppliersIcon} alt="Furniture Suppliers" className="h-10 w-10 mr-4" />
                            <div>
                                <h6 className="text-xl font-bold">Furniture Suppliers</h6>
                                <p className="text-base leading-tight mt-2 mb-0">
                                    Standing desks, sitting desks, ergonomic chairs, soft furnishings.
                                    There’s a plethora of options to choose from.
                                    Let the experts do their thing and get you the best price whilst they’re at it.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-7/12">
                            <p className="text-lg mt-8 font-bold">Example Partners:</p>
                            <div className="flex items-center -m-4">
                                <img className="w-auto h-6 m-4" src={EportaLogo} alt="Eporta logo" />
                                <img className="w-auto h-6 m-4" src={TribesLogo} alt="Tribes logo" />
                                <img className="w-auto h-8 m-4" src={OWNLogo} alt="OW-N logo" />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-end flex-wrap justify-between m-16">
                        <div className="flex items-start w-full w- md:w-4/12">
                            <img src={PhoneBoothSolutionsIcon} alt="Phone Booth Solutions" className="h-10 w-10 mr-4" />
                            <div>
                                <h6 className="text-xl font-bold">Phone Booth Solutions</h6>
                                <p className="text-base leading-tight mt-2 mb-0">
                                    Never enough phone booths? We know the problem.
                                    Get ready made, professional quality, portable phone booths and meeting rooms
                                    for your office. Phew, the sales team will be pleased!
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-7/12">
                            <p className="text-lg mt-8 font-bold">Example Partners:</p>
                            <div className="flex items-center -m-4">
                                <img className="w-auto h-10 m-4" src={ROOMLogo} alt="ROOM logo" />
                                <img className="w-auto h-10 m-4" src={FrameryLogo} alt="Framery logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-48 text-center mb-64 z-1 relative">
                <BotButton
                    botName="OfficeDesignLandBot"
                    type="secondary"
                    className="px-2 py-3 uppercase text-center font-bold shadow-charlie sm:text-2xl w-72 sm:w-96"
                >
                    Start your office design
                </BotButton>
                <p className="text-xs">Answer some quick questions, we’ll do the rest.</p>
            </div>
        </Layout>
    )
}

export default OfficeDesign
