import React, { useLayoutEffect, useState } from 'react';
import Mouse from '../../images/other/mouse.svg'

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const ScrollIndicator = (props) => {

    const [width, height] = useWindowSize();
    let indicatorOffset = 0;
    if (props.ctaPostition) {        
        indicatorOffset = props.ctaPostition.height;
    }
    indicatorOffset = -210;
    const display = "block";
    return (
        <div data-aos="xfade-out" data-aos-anchor-placement="top-center" className="hidden sm:block opacity-100 absolute bottom-0 flex justify-center w-full" style={{ bottom: `${-Math.abs(indicatorOffset)}px`, display: `${display}` }}>
            <div className="flex flex-col items-center">
                <img src={Mouse} />
                <p className="uppercase text-primary">scroll</p>
                <div className="w-px h-20 bg-secondary" />
            </div>
        </div>
    )
};

export default ScrollIndicator;
